import React from "react";
import HubspotForm from "react-hubspot-form";
import {graphql, useStaticQuery} from "gatsby";
import {get, isEmpty} from "lodash";
import Img from "components/image";
import Link from "components/link";
import DefaultLayout from "components/layout/default";
import {IMAGE_BACKGROUND_PROPS} from "constants/index";
import partnersMaps from "images/partners-maps.svg";
import partnerIcon1 from "images/partners-icon-1.svg";
import partnerIcon2 from "images/partners-icon-2.svg";
import partnerIcon3 from "images/partners-icon-3.svg";
import partnerIcon4 from "images/partners-icon-4.svg";
import style from "styles/pages/partners.module.css";

const HUBSPOT_FORM_ID = "4b359376-be5d-4a04-9bea-e7536facc95f";

function PartnersPage(props) {
  let data = useStaticQuery(QUERY);
  let pageContent = data.pageContent.childPagesJson;
  let {phoneNumbers} = data.contactPageContent.childPagesJson;

  function renderPhoneNumbers() {
    if (isEmpty(phoneNumbers)) return null;
    return phoneNumbers.map((item) => {
      return (
        <div key={item.number} className="flex">
          <div className="font-bold w-10 mb-1">{item.label}</div>
          <a href={`tel:${item.number}`}>{item.number}</a>
        </div>
      );
    });
  }

  function renderSection2Items() {
    let items = get(pageContent, "section2.items");
    if (isEmpty(items)) return null;
    let icons = [partnerIcon1, partnerIcon2, partnerIcon3, partnerIcon4];
    return items.map((item, index) => {
      return (
        <div key={`item-${index}`} className={style.partnerItem}>
          <img src={icons[index]} alt="Icon"/>
          <div className="font-bold uppercase mt-4">
            {item.headerText}
          </div>
          <div className="mt-4">
            {item.descriptionText}
          </div>
        </div>
      );
    });
  }

  return (
    <DefaultLayout
      location={props.location}
      title={pageContent.title}
      description={pageContent.description}
      metaImage="/partner-meta-img.png">
      <section className="relative min-h-screen flex items-center">
        <div className="container mx-auto px-4 sm:px-8 z-10 relative">
          <h3 className="font-bold text-4xl lg:text-6xl text-center leading-tight whitespace-pre-wrap">
            {pageContent.section1.headerText}
          </h3>
          <p className="mt-4 text-center max-w-4xl mx-auto">
            {pageContent.section1.subHeaderText}
          </p>
        </div>
        <Img {...IMAGE_BACKGROUND_PROPS}
          fluid={data.backgroundImage.childImageSharp.fluid} alt="Background"/>
      </section>
      <section className="relative min-h-screen flex items-center py-16">
        <div className="container mx-auto px-4 sm:px-8 z-10 relative">
          <h2 className="font-bold text-xl leading-tight">
            {pageContent.section2.headerText}
          </h2>
          <h3 className="font-bold text-4xl lg:text-6xl leading-tight whitespace-pre-wrap">
            {pageContent.section2.subHeaderText}
          </h3>
          <div className="flex mt-8 sm:mt-16 flex-wrap justify-between">
            {renderSection2Items()}
          </div>
        </div>
      </section>
      <section id="form">
        <div className="container mx-auto px-8 py-4 pt-8 z-10 relative max-w-4xl bg-white"
          style={{ minHeight: "200px" }}>
          <HubspotForm portalId={process.env.GATSBY_HUBSPOT_ID} formId={HUBSPOT_FORM_ID}/>
        </div>
      </section>
      <section className="relative min-h-screen flex items-center">
        <div className="container mx-auto px-4 md:px-8 z-10 relative">
          <h3 className="font-bold text-4xl lg:text-6xl leading-tight whitespace-pre-wrap">
            Tigers Around
            <br/>
            The World
          </h3>
          <div>
            <Link to="/about/" className="btn btn-orange mt-6 inline-block">
              About Us
            </Link>
          </div>
          <div className="mt-20">
            {renderPhoneNumbers()}
          </div>
        </div>
        <img src={partnersMaps} alt="Background"
          style={{position: "absolute", right: "0", width: "1000px", maxWidth: "100%", zIndex: 1, pointerEvents: "none"}}/>
      </section>
    </DefaultLayout>
  );
}

const QUERY = graphql`
  {
    pageContent: file(relativePath: {eq: "pages/partners.json"}) {
      childPagesJson {
        title
        description
        section1 {
          headerText
          subHeaderText
        }
        section2 {
          headerText
          subHeaderText
          items {
            headerText
            descriptionText
          }
        }
      }
    }
    contactPageContent: file(relativePath: {eq: "pages/contact.json"}) {
      childPagesJson {
        phoneNumbers {
          label
          number
        }
      }
    }
    backgroundImage: file(relativePath: {eq: "background-partners.png"}) {
      childImageSharp {
        fluid(maxWidth: 1920, toFormat: JPG) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;

export default React.memo(PartnersPage);
